import React, { useRef, useState, useEffect } from 'react'
import { useLoader, useThree } from '@react-three/fiber'

import { Mesh } from 'three'

export default function DragCursor ({ ratio = 1 }) {
  const mesh = useRef<Mesh | null>(null)
  const [hovered, setHovered] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [moving, setMoving] = useState(false)

  useEffect(() => {
    const startDragging = () => setDragging(true)
    const stopDragging = () => {
      setDragging(false)
      setMoving(false)
    }
    const onMove = () => {
      if (!moving) setMoving(true)
    }

    document.body.addEventListener('pointerdown', startDragging)
    document.body.addEventListener('pointermove', onMove)
    document.body.addEventListener('pointerup', stopDragging)

    return () => {
      document.body.removeEventListener('pointerdown', startDragging)
      document.body.removeEventListener('pointermove', onMove)
      document.body.removeEventListener('pointerup', stopDragging)
    }
  }, [])

  useEffect(() => {
    let cursor = ''

    if (hovered) {
      if (dragging && moving) {
        cursor = 'grabbing'
      } else {
        cursor = 'grab'
      }
    }

    document.body.style.cursor = cursor
  }, [hovered, dragging, moving])

  return (
    <mesh
      ref={mesh}
      onPointerOver={e => setHovered(true)}
      onPointerOut={e => setHovered(false)}
    >
      <sphereGeometry args={[ratio * 0.9, 16, 16]} />
      <meshBasicMaterial transparent opacity={0} />
    </mesh>
  )
}
