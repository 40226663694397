import React from 'react'
import { useEnvironment, useCubeTexture, Environment } from '@react-three/drei'

export default function EnvMap () {
  const envMap = useEnvironment({ files: 'assets/envmap.hdr' })
  // const envMap = useCubeTexture(
  //   ['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg'],
  //   { path: 'assets/envmap/' }
  // )

  return <Environment map={envMap} background={false} />
}
