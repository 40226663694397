import React, { useState, useRef, useEffect, Suspense } from 'react'
import { useLoader, useThree, useFrame } from '@react-three/fiber'

import { Mesh, BufferGeometry, MathUtils, Vector3 } from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import DragCursor from './DragCursor'

export default function Spiral () {
  const { viewport } = useThree()
  const mesh = useRef<Mesh | null>(null)
  const [geometry, setGeometry] = useState<BufferGeometry>()
  const gltf = useLoader(GLTFLoader, 'assets/TBA_logo_draco.glb', loader => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
    loader.setDRACOLoader(dracoLoader)
  })

  const isPortrait = viewport.height > viewport.width

  const ratio = isPortrait
    ? viewport.aspect
    : viewport.aspect * (viewport.height / viewport.width) * 0.8
  const scale = new Vector3(ratio, ratio, ratio)

  useEffect(() => {
    if (!Array.isArray(gltf.scene.children)) return

    const mesh = gltf.scene.children[0] as Mesh

    setGeometry(mesh.geometry)
  }, [gltf])

  useFrame(() => {
    if (!mesh.current) return

    mesh.current.rotateZ(-0.00025)
  })

  if (!geometry) return null

  return (
    <Suspense fallback={null}>
      <mesh
        ref={mesh}
        geometry={geometry}
        rotation={[MathUtils.degToRad(90), 0, 0]}
        scale={scale}
      >
        <meshStandardMaterial
          color='#000000'
          roughness={0.1}
          metalness={0.2}
          envMapIntensity={1}
        />
      </mesh>

      <DragCursor ratio={ratio} />
    </Suspense>
  )
}
