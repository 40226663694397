
import React from 'react'


const text1 = "1 / 2 / 3 / 4 / 5 / 6"
const text2 = "The Beach Assembly is a creative studio based in London and Los Angeles formed by a group of artists and professionals working in film, art and advertising."


export default function Header() {
  return (

    <header
      className='absolute container bottom-0 left-0 p-10 z-50 font-body'
    >
      {/* <p className='pb-5 font-display text-xs'>{text1}</p> */}
      <p className='pb-5 max-w-sm'>{text2}</p>
      <p className='font-display text-xs '>
        <a
          href='mailto:pronto@thebeachassembly.com'
          className='no-underline'
        >
          EMAIL
        </a>{' '}
        OR{' '}
        <a
          href='https://www.instagram.com/thebeachassembly/'
          target='_blank'
          className='no-underline'
        >
          SOCIAL
        </a>
      </p>
    </header>
  )
}
