

import Header from './Header'
import Background from './Background'
import WebGL from './WebGL'



function App() {
  return (
    <>
      <Background />
      <Header />
      <WebGL />
    </>
  )
}

export default App
