import React, { useRef, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import {
  useEnvironment,
  Environment,
  useCubeTexture,
  OrbitControls,
  TrackballControls
} from '@react-three/drei'

// import Box from './Box'
import Spiral from './Spiral'
import EnvMap from './EnvMap'

export default function WebGL () {
  return (
    <div className='absolute w-full h-5/6 lg:h-full z-0'>
      <Canvas
        camera={{
          fov: 75,
          position: [0, 0, 1.5]
        }}
      >
        <ambientLight />
        {/* <pointLight position={[3, 3, 3]} /> */}
        {/* <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} /> */}

        <Spiral />
        {/* <OrbitControls
          autoRotate
          autoRotateSpeed={0.25}
          enableZoom={false}
          enablePan={false}
        /> */}

        <TrackballControls
          noPan
          noZoom
          dynamicDampingFactor={0.05}
          // autoRotate
          // autoRotateSpeed={0.25}
          // enableZoom={false}
          // enablePan={false}
        />
        <EnvMap />
      </Canvas>
    </div>
  )
}
