import React, { useEffect } from 'react'

const prevent = (e: Event) => e.preventDefault()

export default function DisableBrowserZoom () {
  useEffect(() => {
    console.warn('WARNING: Pitch zoom was manually disabled in javascript! See DisableBrowserZoom component.')

    window.addEventListener('wheel', prevent, { passive: false })
    window.addEventListener('gesturestart', prevent)
    window.addEventListener('gesturechange', prevent)
    window.addEventListener('gestureend', prevent)

    return () => {
      window.removeEventListener('wheel', prevent)
      window.removeEventListener('gesturestart', prevent)
      window.removeEventListener('gesturechange', prevent)
      window.removeEventListener('gestureend', prevent)
    }
  }, [])

  return null
}
